<template>
  <div class="container evaluate" v-loading="loading">
    <div class="table">
      <div class="table_cell">
        <div class="label">评价详细</div>
        <div class="value">
          {{ details.appraiseContent | noVoid }}
        </div>
      </div>

      <div class="table_cell">
        <div class="label">公司名称</div>
        <div class="value link" @click="toCompanyDetail">
          {{ details.companyName | noVoid }}
        </div>
      </div>

      <div class="table_cell">
        <div class="label">给予单位</div>
        <div class="value">
          {{ details.appraisePublish | noVoid }}
        </div>
      </div>

      <div class="table_cell">
        <div class="label">评价时间</div>
        <div class="value">
          {{ details.appraiseTime | noVoid }}
        </div>
      </div>

      <div class="table_cell">
        <div class="label">评价结果</div>
        <div class="value">
          {{ details.result | noVoid }}
        </div>
      </div>

      <div class="table_cell">
        <div class="label">信息来源</div>
        <div class="value">
          <div>
            <span class="vip" @click="linkToUrl(details.appraiseUrl)">
              {{ details.appraiseSource }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* 评价详情 */
import { getEvaluateDetails } from "@/api/itemizedQuery";

export default {
  data() {
    return {
      id: "",
      details: { appraiseUrl: "" },
      loading: false,
    };
  },

  methods: {
    /* 获取数据 */
    async getData() {
      this.loading = true;
      const params = {
        id: this.id,
      };
      const res = await getEvaluateDetails(params);
      let details = res.data;
      if (details.appraiseResult == "等级") {
        details["result"] = details.appraiseLevel;
      } else if (
        details.appraiseResult == "分数" ||
        details.appraiseResult == "排名"
      ) {
        details["result"] =
          details.appraiseScore +
          (details.appraiseResult == "分数" ? "分" : "");
      } else {
        details["result"] = "--";
      }
      this.details = details;
      this.loading = false;
    },

    /* 操作 */
    // 跳转到网页
    linkToUrl(url) {
      if (url) {
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        a.target = "_blank";
        document.body.appendChild(a);
        a.click();
      } else {
        this.$message.warning("暂无跳转地址");
      }
    },
    // 跳转到企业详情页面
    toCompanyDetail() {
      let id = this.details.companyId;
      if (id) {
        this.$router.push({
          name: "companyDetails",
          params: { name: "评价信息" },
          query: {
            id,
          },
        });
      } else {
        this.$message.warning("暂无企业信息！");
      }
    },

    /* 初始化 */
    __init__() {
      this.id = this.$route.params.id;
      this.getData();
    },
  },

  created() {
    this.__init__();
  },

  activated() {
    this.__init__();
  },
};
</script>

<style lang="less" scoped>
.evaluate {
  padding: 20px;
  background-color: #fff;
  min-height: calc(100vh - 340px);

  .top {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;

    .name {
      font-size: 20px;
      font-weight: bold;
      color: #333;
      margin-right: 20px;
      flex: 1;
    }
  }

  pre {
    margin: 0px !important;
    font-family: "BlinkMacSystemFont";
    font-size: 14px;
  }
}
</style>
